import React, { useState, useEffect, Suspense } from 'react';
import { useDingzhiAPI } from './hooks/useDingzhiApi';
import { getCatList, createqrode, alertShow, getUrlParams } from './assets/scripts/api';
import { registerFunction, unRegisterFunction, webReady, getQueryInfo } from './assets/scripts/native'
import { useWindowScroll } from './hooks/useWindowScroll';
import { getParam, newSetClickCount, version } from './assets/scripts/common';

import './assets/style/reset.scss';
import './App.scss'

import Qrcode from './components/qrcode/index'
const Banner = React.lazy(() => import('./components/banner/index'));
const Header = React.lazy(() => import('./components/header/index'));
const Phone = React.lazy(() => import('./components/phone/index'));
const Load = React.lazy(() => import('./components/load/index'));
const Tx = React.lazy(() => import('./components/tx/index'));
const Bolster = React.lazy(() => import('./components/bolster/index'));
const Cup = React.lazy(() => import('./components/cup/index'));
const Mousepad = React.lazy(() => import('./components/mousepad/index'));

const Text = React.lazy(() => import('./components/text/text'));


const smallui = getParam('smallui') + '' === '1' ? true : false;

let loadTimer = null;

function App(props) {
  const [type, setType] = useState('手机壳');
  const [catid, setCatid] = useState('');
  const [list, setList] = useState([]);
  const [pageno, setPageno] = useState(1);
  const [showCode, setShowCode] = useState(false);
  const [qrcodeurl, setQrcodeurl] = useState('');
  const [loadStatus, setLoadStatus] = useState('');  // nodata    load   more  error
  const [showText, setShowText] = useState(false);

  const banner = {
    ...useDingzhiAPI('//bizhi.shanhutech.cn/live/diy/getBanner', { initialData: {}, banner: (data) => data.data }) || {},
    url: '',
  };
  const catids = useDingzhiAPI('//bizhi.shanhutech.cn/live/diy/GetCategoryListNew', { initialData: [], data: (data) => data.data }) || [];
  const { top, dir, distance } = useWindowScroll(document.querySelector('.scroll-box'));




  // 点击一级分类
  const clickHandler = (_type) => {
    if (type === _type) return false;
    setType(_type);
    setList([]);
    setPageno(1)
    if (_type === '手机壳' && catids[0]) {
      setCatid(catids[0]['id']);
      newSetClickCount('diy_pc', 'click_phonecase_tab');
    } else if (_type === 'T恤') {
      setCatid(24);
      newSetClickCount('diy_pc', 'click_tshirt_tab');
    } else if (_type === '抱枕') {
      setCatid(25);
      newSetClickCount('diy_pc', 'click_bolster_tab');
    } else if (_type === '鼠标垫') {
      setCatid(28);
      newSetClickCount('diy_pc', 'click_mouse_tab');
    } else if (_type === '水杯') {
      setCatid(26);
      newSetClickCount('diy_pc', 'click_cup_tab');
    }
  }
  // 点击2级分类
  function chooseCat(event, _catid, _type) {
    if (_catid === catid) return false;
    event.stopPropagation();
    setType(_type);
    setList([]);
    setPageno(1);
    setCatid(_catid);
  }

  // 获取数据
  function getcatList(cid, pageno) {
    clearTimeout(loadTimer);
    getCatList(cid, pageno).then(res => {
      if (res.errno > 0) return false;
      setList(list.concat(res.list));
      if (pageno < res.total_page) {
        setPageno(pageno * 1 + 1);
        setLoadStatus('more');
      } else {
        if (res.total_page * 1 === 1) {
          if (smallui === 0) {
            if ((type === '手机壳' && res.list.length >= 9) || (type === 'T恤' && res.length >= 11)) {
              setLoadStatus('nodata');
            }
          }
          if (smallui === 1) {
            if ((type === '手机壳' && res.list.length >= 7) || (type === 'T恤' && res.length >= 9)) {
              setLoadStatus('nodata');
            }
          }
          return false;
        }
        setLoadStatus('nodata');
      }
    }).catch(error => {
      loadTimer = setTimeout(() => {
        setLoadStatus('error');
      }, 3000)
    })
  }

  // 生成二维码
  const createQrCode = (url, type) => {
    setShowCode(true);
    createqrode(url, type).then(data => {
      if (data === '') {
        return alertShow('生成跳转二维码失败,请稍后再试!')
      }
      setQrcodeurl(data);
    })
  }
  // 关闭二维码弹窗
  function closeQrCode() {
    setShowCode(false);
    setQrcodeurl('');
  }

  // 重新加载
  function handlerLoadTwice() {
    setLoadStatus('load');
    getcatList(catid, pageno)
  }

  const phoneClick = (url) => {

    let flag = banner && banner.image_diy ? 1 : 0;

    window.location.href = `/sku?url=${url}&smallui=${getUrlParams('smallui', props.location.search)}&banner=${flag}`;
    // props.history.push({
    //   pathname: `/sku?url=${url}`,
    // })
  }

  const changeShowText = (bool) => {
    setShowText(bool);
  }

  // 关于点击的操作
  useEffect(() => {

    if (type === '手机壳' && catid === '' && catids.length > 0) {
      setCatid(catids[0]['id']);
    }
    if (type && catid) {
      setList([]);
      setLoadStatus('');
      getcatList(catid, pageno)
    }
    return () => {
      setTimeout(() => { document.querySelector('.scroll-box').scrollTop = 0; }, 200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, catid, catids])

  // 关于滚动的操作
  useEffect(() => {
    if (dir === 'down' && distance < 80 && loadStatus === 'more') {
      getcatList(catid, pageno)
      setLoadStatus('load');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [top, distance])

  // 首次进入打点
  useEffect(() => {
    if (window && window.external && 'Execute' in window.external) {
      // getQueryInfo().then(function (info) {
      //   if (info && info.appver && version(info.appver, '3.1124.2500.1125')) {
      //     //todo
      //   } else {
      //     webReady();
      //   }
      // })
      // webReady();
      unRegisterFunction();
      registerFunction('on_diy', (res) => {
        const data = JSON.parse(res);
        sessionStorage.setItem('ischange', 1);
        if (data.wp_type === 'static_wp') {
          window.location.href = `/sku?url=${data.url}&smallui=${getUrlParams('smallui', props.location.search)}`;
        } else {
          window.location.href = `/sku?url=none&smallui=${getUrlParams('smallui', props.location.search)}&path=${data.url}`;
        }
      })
    }
    sessionStorage.removeItem('img')
    newSetClickCount('diy_pc', 'mainshow')
  }, [props.location.search])

  return (
    <div className={`${smallui === true ? 'App small' : 'App'} ${banner && banner.image_diy ? 'hasbanner' : ''}`}>
      <Suspense fallback={<div></div>}>
        <Header type={type} catids={catids} catid={catid} clickHandler={(type) => { clickHandler(type) }} chooseCat={chooseCat}></Header>
        <div className="scroll-box">
          {banner && banner.image_diy ? <Banner data={banner} show={changeShowText}></Banner> : null}

          {type === '手机壳' ? <Phone list={list} click={phoneClick}></Phone> : null}
          {type === 'T恤' ? <Tx createCode={(url, type) => { createQrCode(url, type) }} list={list}></Tx> : null}
          {type === '抱枕' ? <Bolster createCode={(url, type) => { createQrCode(url, type) }} list={list}></Bolster> : null}
          {type === '水杯' ? <Cup createCode={(url, type) => { createQrCode(url, type) }} list={list}></Cup> : null}
          {type === '鼠标垫' ? <Mousepad createCode={(url, type) => { createQrCode(url, type) }} list={list}></Mousepad> : null}

          <Load load={handlerLoadTwice} status={loadStatus}></Load>
        </div>
        {showCode === true ? <Qrcode type={type} url={qrcodeurl} closeQrCode={closeQrCode}></Qrcode> : null}
        {showText && <Text close={changeShowText} />}
      </Suspense>

    </div>
  );
}

export default App;
